.Products-content {
    width: 100%;
    flex-direction: column;
    flex-direction: row; /* Change to row to align filters and content side by side */
    align-items: flex-start; /* Align items to the top */
    padding: 20px;
}

.Controls {
    order: 1; /* Make the div appear first */
    display: flex;
    justify-content: flex-end; /* Align items to the end of the container (right side) */
    max-width: 92%;
    width: 100%; /* Make the div take up the full width of its parent */
}

.Filters {
    width: 50%; /* Adjust width as needed */
    margin: 20px auto 20px; /* Add margin to the right and top */
    padding: 0 10px; /* Add padding to the sides */
    text-align: left; /* Align text to the left */
    display: flex;
    flex-direction: column; /* Align filters on top of each other */
    gap: 10px; /* Add some space between the filters */
}

.Filters input,
.Filters select,
.Filters button {
    display: inline-block; /* Display elements inline */
    margin: 10px; /* Add margin */
    width: auto; /* Adjust width as needed */
}


.BikesContainer {
    padding-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Changed auto-fit to auto-fill */
    max-width: 85%;
    column-gap: 20px;
    row-gap: 20px;
    margin: 0 auto;
    order: 3; /* Make the div appear third */
  }

.BikesContainer p {
    margin: 10px 0;
}

.BikesContainer input,
.BikesContainer select,
.BikesContainer button {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.BikesContainer button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.BikesContainer button:hover {
    background-color: #45a049;
}

.ResetFilters {
    display: flex;
    justify-content: center;
}

.toggleFiltersButton {
    padding: 10px;
    margin: 0 20px 0 0; /* Adjust the margin */
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #4CAF50; /* Green */
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.toggleFiltersButton:hover {
    background-color: #45a049;
}

@media (min-width: 768px) {
    .Content {
        flex-direction: row;
        justify-content: space-between;
    }

    .BikesContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .BikesContainer input,
    .BikesContainer select,
    .BikesContainer button {
        width: calc(50% - 10px);
    }
}