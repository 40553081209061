* {
  box-sizing:border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

:root {
  --light-background: rgb(255, 255, 255);
  --text-fontsize: calc(10px + 1vmin);
  --button-color: rgb(81, 95, 173);
} 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Contactpage, .Productspage , .Servicespage, .NewBike, .BikeDetails, .Login, .EditBike{
  background-color: var(--light-background);
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-height: 100vh;
  align-items: center;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.1);
}
.Frontpage{
  background-image: url('images/background.jpg');
  background-repeat: no-repeat, repeat;
  background-position: center 80px;
  background-size: cover;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  min-height: 100vh;
  align-items: center;
  margin: auto;
}

.logo-container{
  justify-content: center;
  align-items: center;
}
.frontpage-logo{
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.NewBike > h3, h4, h2, button, p, input{
  padding: 1%;
}
.AddBike-input{
  margin: 2px;
}
.details{
  width: 80%;
  max-width: 600px;
  margin: 2px;
}
.admin-bikes {
  display: inline-block;
  padding: 1%;
  margin: 3%;
}
.adminbutton {
  padding: 10px;
  background-color: var(--button-color);
  margin: 2%;
}
.button-container {
  display: flex;
  width: 60%;
  justify-content: space-between;
}
.backbutton {
  padding: 1%;
  background-color: var(--button-color);
  margin: 2%;
}

.contact-mapimage {
  display: block;
  width: 80%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 1%;
}

.contact-images {
  width: 100%;
  max-width: 700px;
  padding: 1%;
  align-items: center;
  justify-content: center;
}

.Servicespage-content, .Frontpage-content{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  max-width: 80%;
  min-height: 100vh;
  margin: auto;
  padding: 2%;
  margin-bottom: 10%;
  line-height: 1.5;
}
.Contactpage-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  max-width: 80%;
  margin: auto;
  padding: 2%;
  margin-bottom: 10%;
  line-height: 1.5;
}

.Servicespage-content > h2, .Contactpage-content > h2 {
  padding: 3%;
  margin: 5%;
  text-align: center;
}
.Servicespage-content > p, .Contactpage-content > p {
  max-width: 500px;
  padding: 2%;
  margin-left: 5%;
  font-size: var(--text-fontsize);
}
.Servicespage-content > ul > li {
  margin-left: 7%;
  font-size: var(--text-fontsize)
}
.Servicespage-content > ul > li > ul {
  margin-left: 7%;
  font-size: var(--text-fontsize);
}

.Frontpage-content > p {
  color: white;
  font-size: var(--text-fontsize);
  text-shadow: black 0.1em 0.1em 0.2em;
}

.text {
  padding: 1%;
}

.Content {
  display: flex;
  width: 100%;
  align-items: center;
}

.BikeInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 300px;
  margin: 0 auto;
  padding: 2%;
  border: 2px solid rgb(109, 109, 109);
  text-decoration: none;
}

.BikeName, .BikePrice, .BikeNotes{
  font-size: var(--text-fontsize);
  color: black
}

