.NewBike {
    margin-top: 70px; /* Adjust this value based on the height of your header */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.NewBike h3, .NewBike h4, .NewBike h2 {
    color: #333;
}

.NewBike input, .NewBike textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.NewBike .radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.NewBike .radio-group input {
    margin-right: 5px;
}

.NewBike .AddBike-btn, .NewBike .adminbutton, .NewBike button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.NewBike .AddBike-btn:hover, .NewBike .adminbutton:hover, .NewBike button:hover {
    background-color: #0056b3;
}

.NewBike .admin-bikeslist {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.NewBike .admin-bikes {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(50% - 20px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.NewBike .admin-bikes h3 {
    margin-top: 0;
}

.NewBike .admin-bikes button {
    margin-right: 10px;
}

.NewBike .link-container {
    margin-bottom: 20px;
    margin-top: 10px; /* Add some margin to move it down */
    text-align: left; /* Ensure the text is aligned to the left */
}

.NewBike .link-container a {
    color: #fff;
    background-color: #007bff;
    padding: 10px 15px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
}

.NewBike .link-container a:hover {
    background-color: #0056b3;
}