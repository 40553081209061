.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
}

.navbar-logo {
  cursor: pointer;
  height: 90%;
  max-width: 150px;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #0fa0ee;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.language-button {
  width: 40px;
  height: 25px;
  padding: 0;
  border: none;
}

.language-button img {
  width: 40px;
  height: 25px;
  display: block;
}

@media screen and (max-width: 600px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: flex-start;
    justify-content: right;
  }

  .nav-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #0fa0ee;
    border-radius: 0;
  }

  .language-button {
    width: 40px;
    height: 25px;
    padding: 0;
    border: none;
  }
  
  .language-button img {
    width: 40px;
    height: 25px;
    display: block;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .icon-text {
    color: white;
    text-align: right;
  }


  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #0fa0ee;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #0fa0ee;
    transition: 250ms;
  }

  
}