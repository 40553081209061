.admin-texts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Increased max-width */
    margin: 80px auto 40px auto; /* Adjusted margin to avoid header blocking */
    position: relative;
}

.admin-texts-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.admin-texts-container .text-area-container {
    width: 100%;
    margin-bottom: 20px;
}

.admin-texts-container textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 16px;
    resize: vertical;
}

.admin-texts-container .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px; /* Added margin-top */
}

.admin-texts-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.admin-texts-container button:hover {
    background-color: #0056b3;
}

.admin-texts-container p {
    margin-top: 20px;
    color: #28a745;
    font-size: 16px;
}