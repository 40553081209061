.footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(59, 45, 45);
    color: white;
    text-align: center;
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-content {
    width: 90%; /* Adjust as needed */
  }

  .footer-content p {
    margin-bottom: 10px; /* Adjust as needed */
  }